<template>
  <div class="transfer-page">
    <layout
      :header="tokenByName.name"
      @onBack="$router.push('/')"
      :class="{ blur: showPin }"
      :receive="true"
      @receive="dialog = true"
    >
      <template v-slot:content>
        <v-col cols="12" xs="12" sm="8" md="6" lg="4">
          <v-row no-gutters class="pb-5">
            <v-col cols="12">
              <div class="box-detail rounded-lg">
                <div>
                  <v-toolbar class="rounded-tr-lg rounded-tl-lg" elevation="0">
                    <div class="ml-4">
                      <v-img
                        :src="tokenByName.logoURI"
                        height="40px"
                        width="40px"
                        contain
                      ></v-img>
                    </div>
                    <div class="ml-4">
                      <p class="ma-0 text-xl text-black-color">{{ tokenByName.symbol }}</p>
                      <!-- <p class="ma-0 text-sm text-gray-color">1 S2M = 1฿</p> -->
                    </div>
                    <v-spacer></v-spacer>
                    <div class="mr-4 text-black-color">
                      <p class="ma-0 text-xl text-black-color font-weight-bold">{{ parseUtillETHtoShow(tokenByName.balance) }}</p>
                      <!-- <p class="ma-0 text-sm text-gray-color">= {{ parseUtillETHtoShow(tokenByName.balance) }}฿</p> -->
                    </div>
                  </v-toolbar>
                  <div class="pa-4 rounded-lg" style="background-color: #393D76">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      class="text-right"
                    >
                      <div class="d-flex">
                        <v-text-field
                          flat
                          solo
                          v-model="form.to"
                          :rules="itemRules"
                          label="หมายเลขที่อยู่กระเป๋า"
                          required
                          color="#898989"
                          class="input-textfield"
                        >
                          <template v-slot:append>
                            <v-btn small icon class="white">
                              <img
                                width="24"
                                height="24"
                                src="../../../assets/qrcodeaddress.svg"
                                alt=""
                              />
                            </v-btn>
                          </template>
                        </v-text-field>
                      </div>
                      <v-text-field
                        flat
                        solo
                        type="number"
                        color="#898989"
                        v-model="form.amount"
                        :rules="[
                          (v) => !!v || 'กรุณากรอกข้อมูล',
                          (v) => parseFloat(v) > 0 || 'จำนวนต้องมากกว่า 0',
                          (v) =>
                            parseFloat(v) <=
                              parseFloat(parseUtillETH(tokenByName.balance)) ||
                            'จำนวนไม่ถูกต้อง',
                        ]"
                        label="จำนวนเหรียญ"
                        class="input-textfield"
                        required
                      >
                        <template v-slot:append>
                          <p class="mt-4" style="color:#898989">
                            {{ tokenByName.symbol }}
                          </p>
                        </template>
                      </v-text-field>
                      <v-row class="text-center">
                        <v-col cols="6">
                          <v-btn
                            class="mr-2 white--text btn-font"
                            width="70%"
                            small
                            rounded
                            color="#2CD97B"
                            @click="reset"
                          >
                            เคลียร์
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            :disabled="!valid"
                            color="#2CD97B"
                            class="btn-font"
                            @click="validate"
                            width="70%"
                            small
                            rounded
                          >
                            <span class="white--text"> โอนเหรียญ </span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </div>
              </div>
            </v-col>
            <v-row class="mt-10 ml-4">
              <div class="d-flex align-center">
                <v-icon style="color: white" size="24"> history </v-icon>
                <span class="ml-1" style="color: white;z">ประวัติการทำรายการ</span>
              </div>
            </v-row>
            <v-col cols="12" class="mt-4" v-show="showPage">
              <div
                class="box-detail-history mt-2 rounded-lg"
                style="background-color:white"
                elevation="0"
              >
                <div v-if="historyByToken.length > 0">
                  <history-item
                    v-for="(history, i) in historyByToken"
                    :key="'historyByToken' + i"
                    :hash="history.hash"
                    :from="history.from"
                    :timeStamp="history.timeStamp"
                    :tokenSymbol="history.tokenSymbol"
                    :value="history.value"
                    :lastChild="i >= historyByToken.length - 1"
                  />
                </div>
                <div
                  v-else
                  class="d-flex align-center justify-center"
                  style="height: 100px"
                >
                  ไม่มีข้อมูล...
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </layout>
    <pin-pad
      v-if="showPin"
      header="กรุณาใส่รหัส"
      :verify="true"
      :backward="true"
      @goback="showPin = false"
      @verifyPin="sendToken"
    />
    <v-dialog max-width="350" v-model="dialog">
      <my-qrcode
        :value="
          JSON.stringify({
            type: 'transfer',
            to: ethereumAddress,
            contractAddress: tokenByName.address,
          })
        "
      />
    </v-dialog>

    <!-- <v-btn fab color="#c71e2b" large style="position:fixed;right:10px;bottom:10px;">
      <v-img :src="'/image/buyicon.png'" height="50px" width="50px" contain></v-img>
    </v-btn> -->
  </div>
</template>

<script>
import layout from "../../../components/layout.vue";
import pidPad from "../../../components/pinPad.vue";
import qrcode from "../../../components/qrcode.vue";
import historyItem from "../../../components/historyItem.vue";

export default {
  name: "Token",
  components: {
    layout: layout,
    "pin-pad": pidPad,
    "my-qrcode": qrcode,
    "history-item": historyItem,
  },
  data() {
    return {
      dialog: false,
      showPin: false,
      showPage: false,
      valid: true,
      itemRules: [
        (v) => !!v || "กรุณากรอกข้อมูล",
        (v) => /^0x[a-fA-F0-9]{40}$/g.test(v) || "กรุณากรอกข้อมูลให้ถูกต้อง",
      ],
      form: {
        to: null,
        amount: null,
        text: null,
      },
      transferInstance: null,
      wsProvider: null,
      contract: null,
    };
  },
  computed: {
    tokenByName() {
      return this.$store.getters.tokenByName(this.$route.params.token);
    },
    historyByToken() {
      return this.$store.getters.historyByToken(this.tokenByName.address);
    },
  },
  methods: {
    async sendToken(verify) {
      if (verify.status) {
        this.showPin = false;
        try {
          await this.app_loading(true);
          let wallet = await decrypt(
            localStorage.getItem("encypt_string_mpv"),
            verify.password
          );
          let wallet_json = JSON.parse(wallet);
          let privateKey = await wallet_json.privateKey;
          const provider = await new this.$ethers.providers.JsonRpcProvider(
            "https://rpc.xchain.asia"
          );
          const walletSigner = await new this.$ethers.Wallet(
            privateKey,
            provider
          );
          let amount = await this.$ethers.utils.parseUnits(
            this.form.amount,
            18
          );
          let to_address = await this.form.to;
          let gas_limit = "0x100000";
          await this.app_loading(true);
          let currentGasPrice = await provider.getGasPrice();
          let gas_price = await this.$ethers.utils.hexlify(
            parseInt(currentGasPrice)
          );
          if (this.tokenByName.address != "mainnet") {
            try {
              // general token send
              let contract = await new this.$ethers.Contract(
                this.tokenByName.address,
                this.$abi,
                walletSigner
              );
              let transaction = await contract.transfer(to_address, amount);
              await transaction.wait();
              await this.transferSuccess();
            } catch (err) {
              throw err;
            }
          } else {
            // ether send
            try {
              const tx = {
                from: this.ethereumAddress,
                to: to_address,
                value: amount,
                nonce: provider.getTransactionCount(
                  this.ethereumAddress,
                  "latest"
                ),
                gasLimit: this.$ethers.utils.hexlify(gas_limit), // 100000
                gasPrice: gas_price,
              };
              let transaction = await walletSigner.sendTransaction(tx);
              await transaction.wait();
              await this.transferSuccess();
            } catch (err) {
              throw err;
            }
          }
          this.writeCookies(verify.password);
        } catch (err) {
          console.log(err);
          this.transferError();
        }
      }
    },
    async validate() {
      let valid = await this.$refs.form.validate();
      let pin = this.$cookies.get("pin_mpv");
      if (valid) {
        if (pin == null) {
          this.showPin = true;
        } else {
          const vm = this;
          this.alert_show({
            type: "confirm",
            header: "กรุณายืนยัน",
            title: "ท่านต้องการที่จะทำรายการใช่หรือไหม ?",
          }).then((res) => {
            if (res) {
              let verify = {
                status: true,
                password: pin,
              };
              vm.sendToken(verify);
            }
          });
        }
      }
    },
    async writeCookies(pin) {
      this.$cookies.set("pin_mpv", pin, "15min");
    },
    async transferSuccess() {
      // await this.$store.dispatch("getHistory");
      // await this.$store.dispatch("getBalance");
      await this.app_loading(false);
      this.reset();
      await this.alert_show({
        type: "success",
        header: "สำเร็จ",
        title: "โอนสำเร็จ",
      });
    },
    async transferError() {
      this.app_loading(false);
      this.alert_show({
        type: "error",
        title: "การทำรายการล้มเหลว",
        header: "ล้มเหลว !",
      });
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
  async mounted() {
    if (this.$route.query.to != null) {
      this.form.to = this.$route.query.to;
    }
    this.$nextTick(() => {
      const vm = this;
      var init = function() {
        vm.wsProvider = new vm.$ethers.providers.WebSocketProvider(
          "wss://ws.xchain.asia"
        );

        if (vm.tokenByName.address != "mainnet") {
          vm.contract = new vm.$ethers.Contract(
            vm.tokenByName.address,
            vm.$abi,
            vm.wsProvider
          );
          vm.contract.on("*", async (res) => {
            console.log(res);
            let to = await res.args.to;
            let amount = await res.args.tokens.toString();
            setTimeout(() => {
              vm.$store.dispatch("getHistory");
              vm.$store.dispatch("getBalance");
            }, 2000);
            if (
              res.event == "Transfer" &&
              String(to).toLowerCase() ==
                String(vm.ethereumAddress).toLowerCase()
            ) {
              await vm.$store.commit("SET_ALERT_TOAST", {
                status: true,
                tokenName: vm.tokenByName.name,
                tokenSymbol: vm.tokenByName.symbol,
                amount: amount,
              });
            }
          });
        } else {
          vm.wsProvider.on("pending", async (tx) => {
            let getTx = await vm.wsProvider.getTransaction(tx);
            await vm.wsProvider.once(tx, async (transaction) => {
              if (
                String(transaction.to).toLowerCase() ==
                  String(vm.ethereumAddress).toLowerCase() ||
                String(transaction.from).toLowerCase() ==
                  String(vm.ethereumAddress).toLowerCase()
              ) {
                setTimeout(() => {
                  vm.$store.dispatch("getHistory");
                  vm.$store.dispatch("getBalance");
                }, 2000);

                if (
                  String(transaction.to).toLowerCase() ==
                  String(vm.ethereumAddress).toLowerCase()
                ) {
                  let amount = await getTx.value.toString();
                  await vm.$store.commit("SET_ALERT_TOAST", {
                    status: true,
                    tokenName: "XTH",
                    tokenSymbol: "XTH",
                    amount: amount,
                  });
                }
              }
            });
          });
        }

        vm.wsProvider.on("error", async () => {
          console.log(`Unable to connect to ${ep.subdomain} retrying in 3s...`);
          setTimeout(init, 3000);
        });
        vm.wsProvider.on("close", async (code) => {
          console.log(
            `Connection lost with code ${code}! Attempting reconnect in 3s...`
          );
          vm.wsProvider.terminate();
          setTimeout(init, 3000);
        });
      };
      init();
    });
  },
  async created() {
    this.app_loading(true);
    await this.$store.dispatch("getHistory");
    this.app_loading(false);
    this.showPage = true;
  },
  beforeDestroy() {
    this.wsProvider.off();
  },
};

function decrypt(message = "", key = "") {
  var code = CryptoJS.AES.decrypt(message, key);
  var decryptedMessage = code.toString(CryptoJS.enc.Utf8);

  return decryptedMessage;
}
</script>

<style lang="scss">
.transfer-page {
  .btn-font{
    font-size: 1.1rem;
  }
  .text-xs{
    font-size: 0.75rem;
  }
  .text-sm{
    font-size: 0.875rem;
  }
  .text-lg{
    font-size: 1.125rem;
  }
  .text-xl{
    font-size: 1.25rem;
  }
  background-color: #222766;
  .input-textfield {
    font-size: 1.2rem;
    .v-messages{
      font-size: 1rem;
    }
    .v-input__slot {
      
      border-radius: 10px;
      box-shadow: 0px 0px 6px rgba(28, 104, 166, 0.14);
    }
  }
  .box-detail {
    // background-color: #f4f9ff;
    // box-shadow: 5px 10px;
    box-shadow: 0px 0px 6px rgba(28, 104, 166, 0.14);
  }
  .box-detail-history {
    // background-color: #f4f9ff;
    // box-shadow: 5px 10px;
    box-shadow: 0px 0px 6px rgba(28, 105, 166, 0.13);
  }
  .text-black-color {
    color: #000000;
  }
  .text-gray-color {
    color: #C3C3C3;
  }
}
</style>
