<template>
  <v-row no-gutters class="historyItem" align="center" @click="openLink(hash)">
    <v-col cols="1">
      <div class="icon-shuff">
        <v-img
          v-if="!checkOut(from)"
          size="28"
          src="../assets/receivecoinicon.svg"
        />
        <v-img v-else size="28" src="../assets/sendcoinicon.svg" />
        <!-- <v-icon dark v-if="!checkOut(from)" color="#31B7C0" size="28">
          add_circle
        </v-icon> -->
        <!-- <v-icon dark v-else color="#FF9300" size="28"> remove_circle </v-icon> -->
      </div>
    </v-col>
    <v-col cols="8" class="detail-body">
      <div v-if="!checkOut(from)" class="wd">รับเหรียญ</div>
      <div v-else class="wd">ส่งเหรียญ</div>
      <!-- <div>Tx hash: {{ hash }}</div> -->
      <div v-if="!checkOut(from)">
        {{ DateOnly(new Date(parseInt(timeStamp) * 1000)) }}
        - From {{ from.substr(0, 5) }}...{{ from.substr(42 - 3) }}
      </div>
      <div v-else>
        {{ DateOnly(new Date(parseInt(timeStamp) * 1000)) }}
        - To {{ from.substr(0, 5) }}...{{ from.substr(42 - 3) }}
      </div>
    </v-col>
    <v-col cols="3" class="text-right">
      <span
        class="d-block w-100 money text-xs-right"
        :class="{ wd: checkOut(from) }"
        >{{ checkOut(from) ? "-" : "+" }} {{ parseUtillETHtoShow(value) }}</span
      >
      <span class="sk" :class="{ wd: checkOut(from) }">
        <span>{{ tokenSymbol }}</span>
      </span>
    </v-col>
    <v-col cols="12" class="pt-2 px-2" v-if="!lastChild">
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HistoryItem",
  props: {
    hash: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
    timeStamp: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "0",
    },
    tokenSymbol: {
      type: String,
      default: "XTH",
    },
    lastChild: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openLink(link) {
      window.open("https://exp.xchain.asia/tx/" + link, "_blank");
    },
    checkOut(from) {
      console.log();
      return from.toLowerCase() === String(this.ethereumAddress).toLowerCase();
    },
  },
};
</script>

<style lang="scss">
.historyItem {
  font-size: 1.2rem;
  padding: 8px 12px;
  .icon-shuff {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .detail-body {
    padding-left: 12px !important;
    > div {
      height: 20px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > div:nth-child(2) {
      font-size: 1rem;
      color: #C3C3C3;
      margin-top: 4px;
    }
  }
}

.mt-30 {
  margin-top: 30px;
}

.money {
  color: #C3C3C3;
}

.sk {
  font-size: 1rem;
  color: #C3C3C3;
}

.wd {
  color: #898989;
}
</style>
